<template lang="pug">
    .d-flex.create
        locale-switch-simple.small-locale-switch.create__locale-switch.step-1
        .d-flex.flex-column.position-relative.create__left
            .create__yellow-circle.in-zoom
            svg.create__dashed.in-zoom(xmlns="http://www.w3.org/2000/svg" width="220" height="220" viewBox="0 0 220 220" fill="none")
                circle(cx="110" cy="110" r="109.5" stroke="#8BA2FF" stroke-dasharray="10 10")
            svg.create__dashed-small.in-zoom(v-if="!isMobile" xmlns="http://www.w3.org/2000/svg" width="111" height="111" viewBox="0 0 111 111" fill="none")
                circle(cx="55.5" cy="55.5" r="55" stroke="#8BA2FF" stroke-dasharray="10 10")
            svg.create__dashed-mini.in-zoom(xmlns="http://www.w3.org/2000/svg" width="55" height="45" viewBox="0 0 55 45" fill="none")
                circle(cx="27.5" cy="6.5" r="27" stroke="#8BA2FF" stroke-dasharray="10 10")
            img.create__speech-bubble.in-zoom(src="/assets/img/current/ia-speech-bubble.png" alt="Decor speech bubble")
            .create__blue-circle.in-zoom
            img.create__tg-decor.in-zoom(src="/assets/img/current/tg-decor.png" alt="Decor tg")
            .create__pink-circle.in-zoom
            img.create__diagram.in-zoom(src="/assets/img/current/ia-diagram.png" alt="Decor diagram")
            div.create__paywall
                img.in-zoom(src="/assets/img/current/paywall.svg" alt="Paywall")

        .d-flex.flex-column.create__right
            .d-flex.flex-column.mx-auto.create__wrap.create__wrap--step-2.login__wrap.login__wrap--step-2
                .mb-3
                    my-input(
                        v-model="$v.form.email.$model"
                        :label="$t('user.email')"
                        :icon="''"
                        :type="'email'"
                        :classInput="{'is-error': isFormSendAttempt && $v.form.email.$error}"
                    )
                    .text-danger.b4.mt-1(v-if='isFormSendAttempt && $v.form.email.$dirty && !$v.form.email.required') {{ $t('errors.required.email' )}}
                    .text-danger.b4.mt-1(v-else-if='isFormSendAttempt && $v.form.email.$dirty && !$v.form.email.email') {{ $t('errors.valid.email' )}}
                .mb-5
                    my-input(
                        v-model="$v.form.password.$model"
                        :label="$t('user.password')"
                        :icon="''"
                        :type="'password'"
                        :classInput="{'is-error': isFormSendAttempt && $v.form.password.$error}"
                    )
                    .text-danger.b4.mt-2(v-if='isFormSendAttempt && $v.form.password.$dirty && !$v.form.password.required') {{ $t('errors.required.password' )}}
                .btn.btn-primary.create__btn.mb-3(:class="{'disabled': processing || isloading}" @click="formSubmit")
                    .loader(v-if="isloading")
                    span(v-else) {{ $t('user.login-button') }}
</template>

<script>
import MyInput from '@/components/UI/MyInput';
import { mapGetters, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { MERCHANT_URL, DOMAIN_PROD, DOMAIN, getSPARole } from '@/api/index';
import { SERVICE_STRING } from '@/utils/string';
import Loader from '@/components/Widgets/Loader';
import { STORAGE_PARTNER_LINK_HASH } from '@/api/storage';
import { USER_ROLES } from '@/models/user';
import moment from 'moment';
import { bus } from '@/main.js';
import { STORAGE_LAST_OPENED_ITEM } from '@/api/storage';
import LocaleSwitchSimple from '@/components/Common/LocaleSwitchSimple';

const { required, minLength, email } = require('vuelidate/lib/validators');

export default {
    components: {
        Loader,
        MyInput,
        LocaleSwitchSimple,
    },
    metaInfo() {
        return { title: getSPARole() == USER_ROLES.PARTNER ? bus.$t('login.asPartner') : bus.$t('login.asMerchant') };
    },
    data() {
        return {
            email: '',
            errorEmail: false,
            isDisabled: false,
            isFormSendAttempt: false,
            form: {
                email: '',
                password: '',
            },
            role: getSPARole() == USER_ROLES.PARTNER ? 'partner' : 'merchant',
            isloading: false,
        };
    },
    mixins: [validationMixin],
    validations: {
        form: {
            password: {
                required,
            },
            email: {
                required,
                email,
                minLength: minLength(4),
            },
        },
    },
    mounted() {
        document.body.classList.add('background-login');
    },
    destroyed() {
        document.body.classList.remove('background-login');
    },
    created() {
        if (window.location.host == DOMAIN_PROD) {
            window.location.href = this.loginUrl;
            return;
        }
    },
    computed: {
        ...mapGetters({
            processing: 'auth/isPending',
        }),
    },
    methods: {
        ...mapActions({
            getMe: 'auth/me',
            login: 'auth/login',
            getProjects: 'project/shortList',
        }),
        formSubmit() {
            this.$v.$touch();
            this.isFormSendAttempt = true;

            if (this.$v.$error) return;
            this.isloading = true;

            this.login({
                email: this.form.email,
                password: this.form.password,
                role: this.role,
            })
                .then(this.getMe)
                .catch(v => {
                    if (v == 'Invalid credentials.') {
                        this.$notify('error', 'Login Error', v);
                    } else {
                        this.$notify('error', v);
                    }
                    this.isloading = false;
                });
        },
        async enter() {
            if (this.me.isMerchant) {
                let param =
                    moment().diff(this.me.registeredAt, 'minutes', true) < 1
                        ? '?registration_success=' + SERVICE_STRING.hashCode(moment().format('DD MMM YYYY HH:mm:ss'))
                        : '';
                let id = 'create';

                return this.getProjects().then(v => {
                    if (v && v.length == 1) {
                        id = v[0].id + '/dashboard';
                    } else {
                        let last;

                        if (this.$cookies.get(STORAGE_LAST_OPENED_ITEM)) {
                            last = _.find(v, p => p.id == this.$cookies.get(STORAGE_LAST_OPENED_ITEM));
                        }

                        v = _.orderBy(v, 'viewedAt', 'desc');

                        if (last && last.id) id = last.id + '/dashboard';
                        else if (v && v[0]) id = v[0].id + '/dashboard';
                    }

                    this.goToUI(id, param);
                });
            }

            const name = this.$router.options.routes[0].name
                ? this.$router.options.routes[0].name
                : this.$router.options.routes[0].children[0].name;
            this.$router.push({ name: name });
        },
        goToUI(id, param) {
            return setTimeout(() => {
                if (id == 'create') return this.$router.push({ name: 'add_project' });

                window.location.href = MERCHANT_URL + '/projects/' + id + param;
            }, 1000);
        },
    },
    watch: {
        me(val, vo) {
            if (val && val.id && (!vo || vo.id != val.id)) {
                this.$cookies.remove(STORAGE_PARTNER_LINK_HASH, null, DOMAIN);

                if (this.me.isMerchant && val.emailPersonal == null) {
                    this.isloading = false;
                    return;
                }

                this.enter();
            }
        },
        email() {
            if (!SERVICE_STRING.isValidEmail(this.email)) {
                this.errorEmail = true;
                this.isDisabled = true;
            } else {
                this.errorEmail = false;
                this.isDisabled = false;
            }
        },
    },
};
</script>

<style lang="scss">
@import '~@/assets/css/sass/_mixins.scss';

.create {
    min-height: 100vh;
    overflow: hidden;

    @include respond-below(md) {
        flex-direction: column;

        .login__wrap {
            margin: 20px auto 12px !important;
        }
    }

    &__locale-switch {
        position: absolute;
        top: 48px;
        right: 107px;

        @include respond-below(md) {
            top: 219px;
            right: 74px;
        }

        &.step-1 {
            top: 21px;
            right: 30px;

            @include respond-below(md) {
                top: 217px;
                right: 21px;
            }
        }
    }

    &__closing {
        position: absolute;
        top: 40px;
        right: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        transition: var(--animation-time-short);
        cursor: pointer;

        &:hover,
        &:active {
            opacity: 0.6;
        }

        @include respond-below(md) {
            top: 212px;
            right: 18px;
        }
    }

    &__wrap__title {
        @include respond-below(md) {
            margin-top: 80px !important;
        }
    }

    &__left {
        width: 50%;
        border-radius: 0 250px 250px 0;
        background: linear-gradient(196deg, #5eaefe 0%, #0532d0 100%);
        box-shadow: -20px 0px 100px 0px rgba(68, 116, 238, 0.2);

        @include respond-below(md) {
            width: 100%;
            min-height: 175px;
            border-radius: 0 0 50px 50px;
            background: linear-gradient(286deg, #5eaefe 0%, #0532d0 100%);
        }
    }

    &__right {
        width: 50%;

        @include respond-below(md) {
            padding: 0 18px;
            width: 100%;
        }
    }

    &__btn {
        height: 64px;

        @include respond-below(md) {
            height: 50px;
        }
    }
}

.login {
    &__wrap {
        margin: auto;

        @include respond-below(md) {
            margin-top: 60px;
        }

        &--step-2 {
            margin: auto;

            @include respond-below(md) {
                margin-top: 36px;
            }
        }
    }
}
</style>
